body {
  margin: 0;
  font-family:
    'Noto Sans',
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ededed;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #4865cc;
  border: 2px solid #4865cc;
  border-radius: 6px;
  height: 10%;
}

::-webkit-scrollbar-horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #4865cc;
  border: 2px solid #4865cc;
  border-radius: 6px;
  width: 10%;
}